import React from "react";
import cx from "classnames";
const Spacer = ({ data = {} }) => {
  const { size } = data;
  return (
    <section
      className={cx("spacer", {
        "h-[125px]": size == "default",
        "h-[250px]": size == "large",
      })}
      data-theme="gray"
    ></section>
  );
};

export default Spacer;
